import React, { useState, useEffect } from "react";
import { Grid, Header, Form, Segment, Button } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import { checkEmail } from "../../providers/authProvider";
import createNotification from "../../utils/notification";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [doesEmailExists, setDoesEmailExists] = useState(false);
  const [loadingEmailExists, setLoadingEmailExists] = useState(false);
  const [from, setFrom] = useState(null);

  const location = useLocation();
  const { signIn } = useAuth();

  useEffect(() => {
    if(location.state && location.state.from){
      if(location.state.from.pathname === "/pagamento")
        return setFrom("pagamento");
      else if (location.state.from.pathname === "/produto")
        return setFrom("produto");
    }
  }, [location]);

  function handleSignIn() {
    const payload = { username, password };

    signIn(payload, from);
  }

  async function handleCheckEmail() {
    setLoadingEmailExists(true);
    const result = await checkEmail(username);

    if(result.error){
      setLoadingEmailExists(false);
      return createNotification("error", "Erro", "Ocorreu um problema ao tentar verificar o e-mail");
    }

    if(result.validate){
      setLoadingEmailExists(false);
      return setDoesEmailExists(true);
    }else {
      setLoadingEmailExists(false);
      return createNotification("error", "Erro", "Este e-mail não está cadastrado");
    }
  }

  return (
    <>
      <Grid
        textAlign="center"
        style={{ padding: "8em 0" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Form size="large" style={{ padding: "0 1em" }}>
            <Segment>
              <Header
                as="h4"
                textAlign="left"
                style={{
                  marginTop: 0,
                }}
              >
                {!doesEmailExists ? "Digite o e-mail de sua conta" : "Insira a senha de sua conta"}
              </Header>
              {!doesEmailExists ? (
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="E-mail"
                  value={username}
                  onChange={(e, { value }) => setUsername(value)}
                  required
                  disabled={loadingEmailExists}
                />
              ) : (
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Senha"
                  type="password"
                  value={password}
                  onChange={(e, { value }) => setPassword(value)}
                  required
                />
              )}
              <Button
                style={{ margin: "1em 0" }}
                primary
                fluid
                size="large"
                onClick={doesEmailExists ? handleSignIn : handleCheckEmail}
              >
                {doesEmailExists ? "Entrar" : "Continuar"}
              </Button>

              <a
                style={{
                  color: "var(--primary-text-color)",
                  fontWeight: "300",
                }}
                href="https://shop-api.devari.com.br/accounts/password_reset/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Esqueceu sua senha ?
              </a>
            </Segment>
            <div className="column align-items-center justify-content-center">
              <p style={{ marginBottom: 0, fontSize: 12 }}>
                Ainda não possui conta?
              </p>
              <Link
                style={{
                  marginTop: 0,
                  color: "var(--primary-text-color)",
                  fontWeight: "700",
                }}
                to={{
                  pathname: "/cadastro",
                  state: {
                    from
                  }
                }}
              >
                Cadastre-se
              </Link>
            </div>
          </Form>
        </Grid.Column>
      </Grid>
    </>
  );
}
